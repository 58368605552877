import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import Login from "../components/login"
import List from "../components/list"
import PrivateRoute from "../components/privateRoute"
import Status from "../components/status"
import { refreshSubscription } from "../apollo/client"
import firebase from "../utils/firebase"
import { isBrowser } from "../utils/browser"

const App = () => {
  if (isBrowser) {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        refreshSubscription()
      }
    })
  }

  return (
    <Layout>
      <Status />
      <Router>
        <PrivateRoute path="/app/list" component={List} />
        <Login path="/app/login" />
      </Router>
    </Layout>
  )
}

export default App
