import React from "react"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import { navigate } from "@reach/router"
import firebase from "../utils/firebase"
import useAuthState from "../utils/useAuthState"

import SEO from "./seo"
import { logout, checkHasuraClaim } from "../utils/auth"
import { gql, useMutation } from "@apollo/client"

const LoginPage = () => {
  const [user, loading, error] = useAuthState(firebase)

  const [upsertUser] = useMutation(UPSERT_USER_MUTATION)

  const getUiConfig = auth => {
    return {
      signInFlow: "popup",
      signInOptions: [auth.GoogleAuthProvider.PROVIDER_ID],
      signInSuccessUrl: "/app/list",
      callbacks: {
        signInSuccessWithAuthResult: result => {
          const user = result.user
          console.log("user " + JSON.stringify(user))
          checkHasuraClaim(user).then(() =>
            upsertUser({
              variables: {
                id: user.uid,
                name: user.displayName,
              },
            })
          )
        },
      },
    }
  }

  if (loading) {
    return <div>Loading</div>
  } else if (error) {
    return <div>An error occured</div>
  } else if (user) {
    navigate(`/app/list`)
    return <div></div>
  } else {
    return (
      <div>
        <SEO title="Login" />
        <div>
          {user ? (
            <div>
              <h2>Welcome, {user.displayName}</h2>
              <button onClick={logout}>Sign out</button>
            </div>
          ) : (
            <StyledFirebaseAuth
              uiConfig={getUiConfig(firebase.auth)}
              firebaseAuth={firebase.auth()}
            />
          )}
        </div>
      </div>
    )
  }
}

export default LoginPage

export const UPSERT_USER_MUTATION = gql`
  mutation UpsertUserMutation($id: String!, $name: String!) {
    insert_users(
      objects: { id: $id, name: $name }
      on_conflict: { constraint: users_pkey, update_columns: name }
    ) {
      returning {
        created_at
        id
        name
      }
    }
  }
`
