import React, { useState } from "react"
import SEO from "./seo"
import { gql, useMutation, useSubscription } from "@apollo/client"

const ListPage = () => {
  const { loading, error, data } = useSubscription(GET_TASKS)
  const [addTask] = useMutation(ADD_TASK)
  const [input, setInput] = useState()

  if (loading) return "Loading..."
  if (error) return `Error! ${error.message}`

  const handleSubmit = event => {
    addTask({
      variables: {
        link: input,
      },
    })
    setInput("")
    event.preventDefault()
  }

  return (
    <div>
      <SEO title="list" />
      <h1>My list</h1>

      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={input}
          onChange={e => setInput(e.target.value)}
          placeholder="Add link"
          required
        />
        <button type="submit">Save</button>
      </form>
      <ol>
        {data.task.map((task, i) => (
          <li key={i}>
            <a href={task.link} target="_blank">
              {task.link}
            </a>{" "}
            (Title: {task.title}, Duration: {task.duration}, Thumbnail:{" "}
            {task.thumbnail})
          </li>
        ))}
      </ol>
    </div>
  )
}

export default ListPage

export const GET_TASKS = gql`
  subscription {
    task(order_by: { created_at: desc }) {
      id
      is_completed
      created_at
      completed_at
      user_id
      link
      type
      title
      thumbnail
      duration
    }
  }
`

export const ADD_TASK = gql`
  mutation InsertTask($link: String!) {
    insert_task_one(object: { link: $link }) {
      link
      is_completed
      id
      created_at
    }
  }
`
