import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import firebase from "../utils/firebase"
import useAuthState from "../utils/useAuthState"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const [user, loading, error] = useAuthState(firebase)

  if (loading) {
    return <div>Is loading</div>
  } else if (error) {
    return <div>An error occured</div>
  } else if (!user && location.pathname !== `/app/login`) {
    console.log("Is not logged in. Redirect to login")
    // If we’re not logged in, redirect to the home page.
    navigate(`/app/login`, { replace: true })
    return null
  } else {
    return <Component {...rest} />
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default PrivateRoute
