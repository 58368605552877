import React from "react"
import { logout } from "../utils/auth"
import { navigate } from "@reach/router"

import firebase from "../utils/firebase"
import useAuthState from "../utils/useAuthState"

export default () => {
  const [user, loading, error] = useAuthState(firebase)

  if (loading) {
    return <div>Loading auth status</div>
  } else if (error) {
    return <div>An auth error occurred: {error}</div>
  } else if (!user) {
    return <p className="text-right px-5">You are not logged in</p>
  } else {
    const { displayName, email } = user
    return (
      <p className="text-right px-5">
        Logged in as {displayName} ({email})!
        {` `}
        <a
          href="/"
          onClick={event => {
            event.preventDefault()
            logout().then(() => navigate(`/app/login`))
          }}
        >
          <u>log out</u>
        </a>
      </p>
    )
  }
}
